export interface Provider {
    id?: number;
    rut: string | null;
    name: string;
    contact_email: string;
    contact_phone: string;
    contact_first_name: string;
    contact_last_name: string;
    provider_type_id?: number;
    is_national?: boolean;
    total_guide?: number;

    service_manifest_id?: number
}

export interface ProviderFilters {
    provider_id?: number | null;
    rut?: string | '';
    name?: string | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export const defaultProvider: Provider = {
    name: '',
    rut: '',
    contact_email: '',
    contact_phone: '',
    contact_first_name: '',
    contact_last_name: '',
};


export interface ProviderDataForm {
    id?: number;
    rut: string | null;
    name?: string;
    // fantasy_name?: string;
    // company_type?: string;
    // company_type_id?: number;
    commune?: string;
    commune_id?: number;
    region?: string;
    region_id?: number;
    country?: string;
    country_id?: number;
    address?: string;

    // name_contact?: string;
    contact_email?: string;
    contact_first_name?: string;
    contact_last_name?: string;


    // phone_code_contact?: string;
    contact_phone?: string;
    // is_active?: boolean;
}

// export const defaultProvider: ProviderDataForm = {
//     business_name: '',
//     fantasy_name: '',
//     rut: '',
//     country: '',
//     region_id: undefined,
//     commune_id: undefined,
//     country_id: undefined,
//     address: '',
//     company_type_id: 0,

//     name_contact: '',
//     email_contact: '',
//     phone_code_contact: '',
//     phone_contact: ''
// };
