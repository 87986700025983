import React, { useEffect, useState } from 'react';
import { Provider } from '../../../app/models/Provider';
import useHandleErrorFields from '../../../hooks/useHandleErrorFields';
import useRut from '../../../hooks/useRut';
import SelectTwo, { SelectTwoMapperOptions } from '../../../components/SelectTwo';
type Props = {
    Provider: Provider;
    setProvider: (Provider: Provider) => void;
    errorFields?: any;
    providerTypes?: any[];
    nextId?: number; 
};

const ProviderForm = ({ Provider, setProvider, providerTypes, errorFields, nextId = 0 }: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const { formatRut } = useRut();
    const [tempRut, setTempRut] = useState<string | null>(null);

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    useEffect(() => {
        if (Provider.is_national === undefined) {
            setProvider({ ...Provider, is_national: true });
        }
    }, []);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        if (name == 'rut') {
            setProvider({ ...Provider, [name]: formatRut(value) });
        } else {
            setProvider({ ...Provider, [name]: value });
        }
    };

    const handleCheckChange = () => {
        const isNational = !Provider.is_national;
        const updatedProvider = { ...Provider, is_national: isNational };
        if (!isNational) {
            setTempRut(Provider.rut); // Save the current RUT before clearing it
            updatedProvider.rut = null;
        } else if (tempRut !== null) {
            updatedProvider.rut = tempRut; // Restore the saved RUT
        }
        setProvider(updatedProvider);
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        setProvider({ ...Provider, [name]: value });
    };

    return (
        <>

                <div className="mb-4 mt-3 row needs-validation">
                        <div className="col-6">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="national"
                                    value="national"
                                    checked={Provider.is_national}
                                    id="national"
                                    onChange={handleCheckChange}
                                />
                                <label className="form-check-label" htmlFor="national">
                                    Nacional
                                </label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="foreign"
                                    value="foreign"
                                    checked={!Provider.is_national}
                                    id="foreign"
                                    onChange={handleCheckChange}
                                />
                                <label className="form-check-label" htmlFor="foreign">
                                    Extranjero
                                </label>
                            </div>
                        </div>
                    </div>

                    {Provider.is_national && 
                        <div className="mb-2 row">
                            <label className="col-md-3 col-form-label">Rut</label>
                            <div className="col-md-9">
                                <input
                                    name="rut"
                                    id="rut"
                                    type="text"
                                    className="form-control"
                                    value={Provider.rut || ''}
                                    onChange={handleChange}
                                    onFocus={() => onFocusRemove('rut')}
                                    placeholder="Ingrese el RUT del proveedor"
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('rut')}
                                </div>
                            </div>
                        </div>
                    }

                    <div className="mb-2 row needs-validation">
                        <label className="col-md-3 col-form-label">Nombre</label>
                        <div className="col-md-9">
                            <input
                                name="name"
                                id="name"
                                type="text"
                                className={`form-control ${fieldHasError('name')}`}
                                value={Provider.name}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('name')}
                                placeholder="Ingrese el nombre del proveedor"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('name')}
                            </div>
                        </div>
                    </div>

                    {/*contact_email: '',*/}
                    {/*contact_phone: '',*/}
                    {/*contact_first_name: '',*/}
                    {/*contact_last_name: '',*/}
                    {/*provider_type_id: '',*/}
                    <div className="mb-2 row needs-validation">
                        <label className="col-md-3 col-form-label">Email Contacto</label>
                        <div className="col-md-9">
                            <input
                                name="contact_email"
                                id="contact_email"
                                type="text"
                                className={`form-control ${fieldHasError('contact_email')}`}
                                value={Provider.contact_email}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('contact_email')}
                                placeholder="Ingrese el email del contacto del proveedor"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('contact_email')}
                            </div>
                        </div>
                    </div>

                    <div className="mb-2 row needs-validation">
                        <label className="col-md-3 col-form-label">Teléfono Contacto</label>
                        <div className="col-md-9">
                            <input
                                name="contact_phone"
                                id="contact_phone"
                                type="text"
                                className={`form-control ${fieldHasError('contact_phone')}`}
                                value={Provider.contact_phone}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('contact_phone')}
                                placeholder="Ingrese el teléfono del contacto del proveedor"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('contact_phone')}
                            </div>
                        </div>
                    </div>

                    <div className="mb-2 row needs-validation">
                        <label className="col-md-3 col-form-label">Nombre Contacto</label>
                        <div className="col-md-9">
                            <input
                                name="contact_first_name"
                                id="contact_first_name"
                                type="text"
                                className={`form-control ${fieldHasError('contact_first_name')}`}
                                value={Provider.contact_first_name}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('contact_first_name')}
                                placeholder="Ingrese el nombre del contacto del proveedor"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('contact_first_name')}
                            </div>
                        </div>
                    </div>

                    <div className="mb-2 row needs-validation">
                        <label className="col-md-3 col-form-label">Apellido Contacto</label>
                        <div className="col-md-9">
                            <input
                                name="contact_last_name"
                                id="contact_last_name"
                                type="text"
                                className={`form-control ${fieldHasError('contact_last_name')}`}
                                value={Provider.contact_last_name}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('contact_last_name')}
                                placeholder="Ingrese el apellido del contacto del proveedor"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('contact_last_name')}
                            </div>
                        </div>
                    </div>

                    <div className="mb-2 row needs-validation">
                        <label className="col-md-3 col-form-label">Tipo Proveedor</label>
                        <div className="col-md-9">
                            <SelectTwo
                                name="provider_type_id"
                                inputValue={Provider.provider_type_id}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('provider_type_id', value.value)
                                }
                                options={SelectTwoMapperOptions(providerTypes ?? [])}
                                placeholder="Seleccione el tipo de proveedor"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('provider_type_id')}
                            </div>
                        </div>
                    </div>
        </>
    );
};

export default ProviderForm;
