import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { PaymentStatusDataForm, PaymentStatusesStatus, PaymentStatusFilters } from '../../../../app/models/PaymentStatus';
import { momentParseDate} from '../../../../helpers';
import PaymentStatusFilter from './PaymentStatusFilter';
import PaymentStatusChangeStatus from '../PaymentStatusChangeStatus';
import { Link } from 'react-router-dom';

interface Props {
    paymentStatuses: PaymentStatusDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (PaymentStatusId: number) => void;
    show?: (PaymentStatusId: number) => void;
    destroy?: (PaymentStatusId: number) => void;
    documentPaymentStatus?: (paymentStatusId: string) => void;
    filter: PaymentStatusFilters;
    setFilter: (filter: PaymentStatusFilters) => void;
    paginationRowsPerPageOptions?: any[];
    statuses: PaymentStatusesStatus[];
    callbackSuccess: () => void;
}

const PaymentStatusDataTable = ({
    paymentStatuses,
    totalRows,
    loading = false,
    documentPaymentStatus,
    edit,
    destroy,
    filter,
    setFilter,
    statuses,
    callbackSuccess
}: Props) => {
    const columns = [
        {
            name: 'ID',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: PaymentStatusDataForm, index: any, column: any) => (
                <span className="">
                    {' '}
                    {/* Agrega la clase de Bootstrap que desees */}
                    {row.id}
                </span>
            )
        },
        {
            name: 'Despacho',
            selector: (row: any) => row.dispatch_program_id,
            sortable: true,
            sortField: 'dispatch_program_id',
            cell: (row: any, index: any, column: any) => {
                return row.dispatch_program_id ? (
                    <Link to={`/dispatch-programs/${row.dispatch_program_id}/show`}>
                        #{row.dispatch_program_id}
                    </Link>
                ) : null;
            }
        },
        {
            name: 'Cliente',
            selector: (row: any) => row.client,
            sortable: true,
            sortField: 'client',
            cell: (row: PaymentStatusDataForm, index: any, column: any) => (
                <span className="">
                <div
                    dangerouslySetInnerHTML={{
                        __html: row.client ? String(row.client) : ''
                    }}
                />
            </span>
            )
        },
        {
            name: 'Email',
            selector: (row: any) => row.contact_email,
            sortable: true,
            sortField: 'contact_email',
            minWidth: '210px',
            cell: (row: PaymentStatusDataForm, index: any, column: any) => (
                <span className="">{row?.contact_email}</span>
            )
        },
        // {
        //     name: 'Número de contacto',
        //     selector: (row: any) => row.phone_contact,
        //     sortable: true,
        //     sortField: 'contact_phone',
        //     cell: (row: PaymentStatusDataForm, index: any, column: any) => (
        //         <span className="">{row?.contact_phone}</span>
        //     )
        // },
        {
            name: 'Condición de pago',
            selector: (row: any) => row.payment_condition,
            sortable: true,
            sortField: 'payment_condition',
            minWidth: '150px',
            cell: (row: PaymentStatusDataForm, index: any, column: any) => (
                <span className="">{row?.payment_condition}</span>
            )
        },
        {
            name: 'Neto',
            selector: (row: any) => row.amount,
            sortable: true,
            sortField: 'amount',
            cell: (row: any, index: any, column: any) => (
                <span className="">{row?.total_neto}</span>
            )
        },
        {
            name: 'Impuesto',
            selector: (row: any) => row.amount,
            sortable: true,
            sortField: 'amount',
            cell: (row: any, index: any, column: any) => (
                <span className="">{row?.total_tax}</span>
            )
        },
        {
            name: 'Total',
            selector: (row: any) => row.amount,
            sortable: true,
            sortField: 'amount',
            cell: (row: any, index: any, column: any) => (
                <span className="">{row?.total}</span>
            )
        },
        {
            name: 'Fecha Emisión',
            selector: (row: any) => row?.issue_date,
            sortable: true,
            sortField: 'created_at',
            cell: (row: PaymentStatusDataForm, index: any, column: any) => (
                <span className="">{momentParseDate(row?.issue_date)}</span>
            )
        },
        {
            name: 'Estado',
            selector: (row: any) => row?.status,
            sortable: true,
            sortField: 'status',
            cell: (row: any) => {

                return (
                  <div>
                    <PaymentStatusChangeStatus
                      paymentStatusId={row.id!}
                      statuses={statuses}
                      selectedStatus={row.status}
                      callbackSuccess={callbackSuccess}
                    />
                  </div>
                );
              },
        },

        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            width: '150px',
            cell: (row: PaymentStatusDataForm, index: any, column: any) => (
                <div className="">
                    {documentPaymentStatus && (
                        <ButtonTableAction
                            callbackFunction={() => documentPaymentStatus(row.document ?? '')}
                            classIcon={'mdi mdi-download'}
                            colorIcon={row.document ? 'text-success' : 'text-secondary'}
                            locked={!row.document}
                            errorMessage={'El documento aun se esta generando.'}
                            title={'Descargar'}
                        />


                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row?.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row?.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <PaymentStatusFilter filter={filter} setFilter={setFilter} />
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={paymentStatuses}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />


        </>
    );
};

export default PaymentStatusDataTable;