import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import useBusinessNamesService from '../../../app/services/hooks/useBusinessService';
import { BusinessNameDataForm, defaultBusinessNameForm } from '../../../app/models/BusinessName';
import { ProviderType } from '../../../app/models/ProviderType';
import BusinessNameForm from './forms/BusinessNameForm';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';


interface Props {
    businessNameId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const BusinessNameEdit = ({ businessNameId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditBusinessName, editBusinessName, fetchingUpdateBusinessName, updateBusinessName } =
        useBusinessNamesService();

    const [businessName, setBusinessName] = useState<BusinessNameDataForm>(defaultBusinessNameForm);
    const [providerTypes, setProviderTypes] = useState<ProviderType[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando razón social...');
        editBusinessName(businessNameId, {
            onSuccess: (response: ServiceResponse) => {
                setBusinessName(response.data.business_name);
                setProviderTypes(response.data.provider_types);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando razón social...');
        updateBusinessName(businessNameId, businessName, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditBusinessName ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <BusinessNameForm
                        businessNameDataForm={businessName}
                        setBusinessNameDataForm={setBusinessName}
                        providerTypes={providerTypes}
                        errorFields={errorFields}
                        setErrorFields={setErrorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingUpdateBusinessName} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={update} locked={fetchingUpdateBusinessName} />
                </div>
            </div>
        </>
    );
};

export default BusinessNameEdit;
