import React, { useEffect, useRef, useState } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { BankAccountType } from '../../../../app/models/BankAccountType';
import { Currency } from '../../../../app/models/Currency';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import LazyLoading from '../../../../components/LazyLoading';

import { formatAmount, formatMoney, setCleanTextAreaError } from '../../../../utils/utils';
import InputButtonFile from '../../../../components/inputs/InputButtonFile';

import { Bank } from '../../../../app/models/Bank';
import { CheckingAccountDataForm } from '../../../../app/models/CheckingAccount';
import { BusinessName } from '../../../../app/models/BusinessName';
import { BusinessEntity } from '../../../../app/models/BusinessEntity';
import useBusinessNamesService from '../../../../app/services/hooks/useBusinessService';

type Props = {
    checkingAccountDataForm: CheckingAccountDataForm;
    setCheckingAccountDataForm: (checkingAccountDataForm: CheckingAccountDataForm) => void;
    senderBusinessNames: BusinessName[];
    senderBusinessEntities: BusinessEntity[];
    receiverBusinessNames: BusinessName[];
    receiverBusinessEntities: BusinessEntity[];
    senderBanks: Bank[];
    receiverBanks: Bank[];
    senderBankAccountTypes: BankAccountType[];
    receiverBankAccountTypes: BankAccountType[];
    senderCurrencies: Currency[];
    receiverCurrencies: Currency[];
    errorFields?: any;
};

const CheckingAccountForm = ({
    checkingAccountDataForm,
    setCheckingAccountDataForm,
    senderBusinessNames,
    senderBusinessEntities,
    receiverBusinessNames,
    receiverBusinessEntities,
    senderBanks,
    receiverBanks,
    senderBankAccountTypes,
    receiverBankAccountTypes,
    senderCurrencies,
    receiverCurrencies,
    errorFields,
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors, errors } = useHandleErrorFields();
    const {
        getBusinessNameList
    } = useBusinessNamesService();
    const [ businessSender, setBusinessSender ] = useState<any[]>([]);
    const [ businessReceiver, setBusinessReceiver ] = useState<any[]>([]);
    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;

        setCheckingAccountDataForm({ ...checkingAccountDataForm, [name]: value });
    };

    const handleChangeDocumentFile = (e: FileList | null, name: string) => {
        if (e) {
            
            const obj = { [name]:  e[0]};
            
            console.log(obj)
            setCheckingAccountDataForm({ ...checkingAccountDataForm, ...obj });
        }
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null | undefined) => {
        const obj = { [name]: value };

        setCheckingAccountDataForm({ ...checkingAccountDataForm, ...obj });
    };

    const handleRemoveFile = (name: string) => {

        setCheckingAccountDataForm({
            ...checkingAccountDataForm,
            [name]: undefined
        });
        const fileInput = document.getElementById(name) as HTMLInputElement | null;
        if (fileInput) {
            fileInput.value = '';
        }

    };

    useEffect(() => {
        if (checkingAccountDataForm.sender_business_entity){
            getBusinessNameList(checkingAccountDataForm.sender_business_entity, {
                onSuccess: (response) => {
                    setBusinessSender(response.data.businesses_name)
                }
        });
        }
    },[checkingAccountDataForm.sender_business_entity])

    useEffect(() => {
        if (checkingAccountDataForm.receiver_business_entity){
            getBusinessNameList(checkingAccountDataForm.receiver_business_entity, {
                onSuccess: (response) => {
                    setBusinessReceiver(response.data.businesses_name)
                }
        });
        }
    },[checkingAccountDataForm.receiver_business_entity])

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label"> Fecha de emisión </label>
                    <input
                        type="date"
                        className={`form-control ${fieldHasError('issue_date')}`}
                        name="issue_date"
                        id="issue_date"
                        value={checkingAccountDataForm.issue_date}
                        onChange={handleChange}
                        placeholder="Ingrese una fecha de emisión"
                        onFocus={() => onFocusRemove('issue_date')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('issue_date')}
                    </div>
                </div>
            </div>
           <hr />
           <h4>Datos Emisor</h4>
           <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Entidad</label>

                    <SelectTwo
                        name="sender_business_entity"
                        id="sender_business_entity"
                        inputValue={checkingAccountDataForm.sender_business_entity}
                        options={SelectTwoMapperOptions(senderBusinessEntities)}
                        hasError={fieldHasError('sender_business_entity') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('sender_business_entity', value?.value)
                        }
                        placeholder={'Seleccione entidad'}
                        onFocus={() => onFocusRemove('sender_business_entity')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('sender_business_entity')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Razon social</label>

                    <SelectTwo
                        name="sender_business_name_id"
                        id="sender_business_name_id"
                        inputValue={checkingAccountDataForm.sender_business_name_id}
                        options={SelectTwoMapperOptions(businessSender, 'id', ['rut'])}
                        hasError={fieldHasError('sender_business_name_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('sender_business_name_id', value?.value)
                        }
                        placeholder={'Seleccione razón social'}
                        onFocus={() => onFocusRemove('sender_business_name_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('sender_business_name_id')}
                    </div>
                </div>

            </div>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Banco</label>

                    <SelectTwo
                        name="sender_bank_id"
                        id="sender_bank_id"
                        inputValue={checkingAccountDataForm.sender_bank_id}
                        options={SelectTwoMapperOptions(senderBanks)}
                        hasError={fieldHasError('sender_bank_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('sender_bank_id', value?.value)
                        }
                        placeholder={'Seleccione banco'}
                        onFocus={() => onFocusRemove('sender_bank_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('sender_bank_id')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Tipo de cuenta</label>

                    <SelectTwo
                        name="sender_account_type_id"
                        id="sender_account_type_id"
                        inputValue={checkingAccountDataForm.sender_account_type_id}
                        options={SelectTwoMapperOptions(senderBankAccountTypes)}
                        hasError={fieldHasError('sender_account_type_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('sender_account_type_id', value?.value)
                        }
                        placeholder={'Seleccione tipo'}
                        onFocus={() => onFocusRemove('sender_account_type_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('sender_account_type_id')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">N° de cuenta</label>
                    <input
                        name="sender_account_number"
                        id="sender_account_number"
                        type="text"
                        onChange={handleChange}
                        className={`form-control ${fieldHasError('sender_account_number')}`}
                        value={checkingAccountDataForm.sender_account_number}
                        onFocus={() => onFocusRemove('sender_account_number')}
                        placeholder="Ingrese el número de cuenta"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('sender_account_number')}
                    </div>
                </div>
            </div>
              
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Monto</label>
                    <input
                        name='sender_amount'
                        id='sender_amount'
                        onChange={handleChange}
                        value={checkingAccountDataForm.sender_amount}
                        className={`form-control ${fieldHasError(`sender_amount`)}`}
                        onFocus={() => onFocusRemove(`sender_amount`)}
                        placeholder="Ingrese el monto"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('sender_amount')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Moneda</label>
                    <SelectTwo
                        name="sender_currency_id"
                        id="sender_currency_id"
                        inputValue={checkingAccountDataForm.sender_currency_id}
                        options={SelectTwoMapperOptions(senderCurrencies)}
                        hasError={fieldHasError('sender_currency_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('sender_currency_id', value?.value)
                        }
                        placeholder={'Seleccione moneda'}
                        onFocus={() => onFocusRemove('sender_currency_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('sender_currency_id')}
                    </div>
                </div>
            </div>
            
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-md-12 col-form-label">Comprobante</label>
                    <InputButtonFile
                        handleChangeDocumentFile={handleChangeDocumentFile}
                        name={'sender_file'}
                        objectFile={checkingAccountDataForm?.sender_file}
                        handleRemoveFile={(value: any) => handleRemoveFile('sender_file')}
                        onFocus={() => onFocusRemove('sender_file')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('sender_file')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-md-12 col-form-label">Descripción</label>
                    <textarea
                        className={`form-control ${fieldHasError('sender_description')}`}
                        name="sender_description"
                        id="sender_description"
                        value={checkingAccountDataForm.sender_description}
                        onChange={handleChange}
                        placeholder="Ingrese una descripción"
                        onFocus={setCleanTextAreaError}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('sender_description')}
                    </div>
                </div>
            </div>

            <hr />
            <h4>Datos Receptor</h4>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Entidad</label>

                    <SelectTwo
                        name="receiver_business_entity"
                        id="receiver_business_entity"
                        inputValue={checkingAccountDataForm.receiver_business_entity}
                        options={SelectTwoMapperOptions(receiverBusinessEntities)}
                        hasError={fieldHasError('receiver_business_entity') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('receiver_business_entity', value?.value)
                        }
                        placeholder={'Seleccione entidad'}
                        onFocus={() => onFocusRemove('receiver_business_entity')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('receiver_business_entity')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Razon social</label>

                    <SelectTwo
                        name="receiver_business_name_id"
                        id="receiver_business_name_id"
                        inputValue={checkingAccountDataForm.receiver_business_name_id}
                        options={SelectTwoMapperOptions(businessReceiver, 'id', ['rut'])}
                        hasError={fieldHasError('receiver_business_name_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('receiver_business_name_id', value?.value)
                        }
                        placeholder={'Seleccione razón social'}
                        onFocus={() => onFocusRemove('receiver_business_name_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('receiver_business_name_id')}
                    </div>
                </div>

            </div>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Banco</label>

                    <SelectTwo
                        name="receiver_bank_id"
                        id="receiver_bank_id"
                        inputValue={checkingAccountDataForm.receiver_bank_id}
                        options={SelectTwoMapperOptions(receiverBanks)}
                        hasError={fieldHasError('receiver_bank_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('receiver_bank_id', value?.value)
                        }
                        placeholder={'Seleccione banco'}
                        onFocus={() => onFocusRemove('receiver_bank_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('receiver_bank_id')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Tipo de cuenta</label>

                    <SelectTwo
                        name="receiver_account_type_id"
                        id="receiver_account_type_id"
                        inputValue={checkingAccountDataForm.receiver_account_type_id}
                        options={SelectTwoMapperOptions(receiverBankAccountTypes)}
                        hasError={fieldHasError('receiver_account_type_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('receiver_account_type_id', value?.value)
                        }
                        placeholder={'Seleccione tipo'}
                        onFocus={() => onFocusRemove('receiver_account_type_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('receiver_account_type_id')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">N° de cuenta</label>
                    <input
                        name="receiver_account_number"
                        id="receiver_account_number"
                        type="text"
                        onChange={handleChange}
                        className={`form-control ${fieldHasError('receiver_account_number')}`}
                        value={checkingAccountDataForm.receiver_account_number}
                        onFocus={() => onFocusRemove('receiver_account_number')}
                        placeholder="Ingrese el número de cuenta"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('receiver_account_number')}
                    </div>
                </div>
            </div>
            
        
                
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Monto</label>
                    <input
                        name='receiver_amount'
                        id='receiver_amount'
                        onChange={handleChange}
                        value={checkingAccountDataForm.receiver_amount}
                        className={`form-control ${fieldHasError(`receiver_amount`)}`}
                        onFocus={() => onFocusRemove(`receiver_amount`)}
                        placeholder="Ingrese el monto"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('receiver_amount')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Moneda</label>
                    <SelectTwo
                        name="receiver_currency_id"
                        id="receiver_currency_id"
                        inputValue={checkingAccountDataForm.receiver_currency_id}
                        options={SelectTwoMapperOptions(receiverCurrencies)}
                        hasError={fieldHasError('receiver_currency_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('receiver_currency_id', value?.value)
                        }
                        placeholder={'Seleccione moneda'}
                        onFocus={() => onFocusRemove('receiver_currency_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('receiver_currency_id')}
                    </div>
                </div>
            </div>
            
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-md-12 col-form-label">Comprobante</label>
                    <InputButtonFile
                        handleChangeDocumentFile={handleChangeDocumentFile}
                        name={'receiver_file'}
                        handleRemoveFile={(value: any) => handleRemoveFile('receiver_file')}
                        objectFile={checkingAccountDataForm?.receiver_file}
                        onFocus={() => onFocusRemove('receiver_file')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('receiver_file')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-md-12 col-form-label">Descripción</label>
                    <textarea
                        className={`form-control ${fieldHasError('receiver_description')}`}
                        name="receiver_description"
                        id="receiver_description"
                        value={checkingAccountDataForm.receiver_description}
                        onChange={handleChange}
                        placeholder="Ingrese una descripción"
                        onFocus={setCleanTextAreaError}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('receiver_description')}
                    </div>
                </div>
            </div>


        </>
    );
};

export default CheckingAccountForm;
