import React, { useContext } from 'react';
import { AuthContext } from '../../../../contexts/AuthContext';
import { InternalSettlementFilters } from '../../../../app/models/InternalSettlement';
import { BusinessName } from '../../../../app/models/BusinessName';
import { BusinessEntity } from '../../../../app/models/BusinessEntity';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';

type Props = {
    filter: InternalSettlementFilters;
    setFilter: (filter: InternalSettlementFilters) => void;
    businessNames: BusinessName[];
    businessEntities: BusinessEntity[];
    businessEntitiesCompare: BusinessEntity[];
    businessNamesCompare: BusinessName[];
};

const InternalSettlementFilter = (
    { 
        filter, 
        setFilter, 
        businessNames, 
        businessEntities,
        businessEntitiesCompare,
        businessNamesCompare
    }: Props) => {
    const { auth } = useContext(AuthContext);

    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {

        const obj = { [e.target.name]: e.target.value };

        if(e.target.name === 'business_entity'){
            obj.business_name_id = '';
        }
   
        setFilter({
            ...filter,
            ...obj
        });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        if(name === 'business_name_id' && value == undefined){
            obj.business_name_id = '';
        }

        setFilter({ ...filter, ...obj });
    };

    return (
        <>
            <div className="row justify-content-start">
                <div className="col-lg-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="issue_date_from">
                            Fecha de emisión (desde)
                        </label>
                        <input
                            title="Fecha de emisión"
                            type="date"
                            id="issue_date_from"
                            name="issue_date_from"
                            className="form-control form-control-sm"
                            value={filter.issue_date_from}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-lg-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="issue_date_until">
                            Fecha de emisión (hasta)
                        </label>
                        <input
                            title="Fecha de emisión"
                            type="date"
                            id="issue_date_until"
                            name="issue_date_until"
                            className="form-control form-control-sm"
                            value={filter.issue_date_until}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>

                <div className="col-lg-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="movement_type">
                            Tipo de Movimiento
                        </label>
                        <select
                            id="movement_type"
                            name="movement_type"
                            className="form-control form-select-sm"
                            value={filter.movement_type ?? ''}
                            onChange={(e) => handlerSelectFilter(e)}
                        >
                            <option key={''} value="">
                                Todos
                            </option>
                            <option key={'CREDIT'} value="CREDIT">
                                Cargo
                            </option>
                            <option key={'DEBIT'} value="DEBIT">
                                Abono
                            </option>
                        </select>
                    </div>
                </div>
                
              
            </div>
            <div className="row justify-content-start">
            {/* Column for "Entidad" on the left */}
            <div className="col-lg-6">
                <div className="mb-3">
                    <label className="font-size-10 mb-1" htmlFor="business_entity">
                        Entidad
                    </label>
                    <select
                        id="business_entity"
                        name="business_entity"
                        className="form-control form-select-sm"
                        value={filter.business_entity ?? ''}
                        onChange={(e) => handlerSelectFilter(e)}
                    >
                        <option key={''} value="">
                            Todos
                        </option>
                        {businessEntities?.map((businessEntity: BusinessEntity) => (
                            <option key={businessEntity.id} value={businessEntity.id}>
                                {businessEntity.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="col-lg-6">
                <div className="mb-3">
                    <label className="font-size-10 mb-1" htmlFor="business_name_id">
                        Razón Social
                    </label>
                    <SelectTwo
                        id="business_name_id"
                        name="business_name_id"
                        inputValue={filter.business_name_id}
                        options={SelectTwoMapperOptions(businessNames ?? [])}
                        onChange={(value: any) => handleChangeSelectTwo('business_name_id', value?.value)}
                        placeholder="Seleccione opción"
                        isClearable={true}
                        size="small"
                    />
                </div>
            </div>

            
            <div className="col-lg-6">
                <div className="mb-3 mt-3">
                    <label className="font-size-10 mb-1" htmlFor="business_entity_compares">
                        Entidad comparativa
                    </label>
                    <SelectTwo
                        id="business_entity_compares"
                        name="business_entity_compares"
                        inputValue={filter.business_entity_compares}
                        options={SelectTwoMapperOptions(businessEntitiesCompare!)}
                        onChange={(value: any) =>
                            setFilter({
                                ...filter,
                                business_entity_compares: value,
                                business_entity_compare_list: value.map((item: any) => item.value)
                            })
                        }
                        isMulti={true}
                        isClearable
                        placeholder="Seleccione M. Pago"
                        size="small"
                    />
                </div>
            </div>

            <div className="col-lg-6">
                <div className="mb-3 mt-3">
                    <label className="font-size-10 mb-1" htmlFor="business_name_compares">
                        Razón Social comparativa
                    </label>
                    <SelectTwo
                        id="business_name_compares"
                        name="business_name_compares"
                        inputValue={filter.business_name_compares}
                        options={SelectTwoMapperOptions(businessNamesCompare!)}
                        onChange={(value: any) =>
                            setFilter({
                                ...filter,
                                business_name_compares: value,
                                business_name_compare_list: value.map((item: any) => item.value)
                            })
                        }
                        isMulti={true}
                        isClearable
                        placeholder="Seleccione M. Pago"
                        size="small"
                    />
                </div>
            </div>
        </div>

        </>
    );
};

export default InternalSettlementFilter;
