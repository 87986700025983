import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { RouterParams } from '../../../app/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import { AuthContext } from '../../../contexts/AuthContext';
import useSocietyService from '../../../app/services/hooks/useSocietyService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import LazyLoading from '../../../components/LazyLoading';
import { toast } from 'react-toastify';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import useNavigationPage from '../../../hooks/useNavigationPage';

import { Tab, Tabs } from 'react-bootstrap';
import { defaultSocietyForm, SocietyDataForm } from '../../../app/models/Society';
import SocietyForm from '../components/forms/SocietyForm';
import { Client } from '../../../app/models/Client';
import { Provider } from '../../../app/models/Provider';
import SocietyClientContainer from '../components/SocietyClientContainer';
import SocietyProviderContainer from '../components/SocietyProviderContainer';
import BankAccountSocietyContainer from '../components/BankAccountSocietyContainer';
import SocietyContactContainer from '../components/SocietyContactContainer';

const SocietyEdit = () => {
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { id } = useParams<RouterParams>();

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Sociedades',
            url: '/societies',
            isActive: false
        },
        {
            name: 'Editar sociedad #' + id,
            url: '/societies/' + id + '/edit',
            isActive: true
        }
    ];

    const { auth } = useContext(AuthContext);

    const {
        editSociety,
        fetchingEditSociety,
        fetchingShowSociety,
        fetchingUpdateSociety,
        updateSociety,
    } = useSocietyService();

   
    const [society, setSociety] = useState<SocietyDataForm>(defaultSocietyForm);

    const [clients, setClients] = useState<Client[]>([]);
    const [providers, setProviders] = useState<Provider[]>([]);

    const [errorFields, setErrorFields] = useState<any>();


    useEffect(() => {
        reloadModule();
    }, [id]);

    const edit = (id: number) => {
        editSociety(id, {
            onSuccess: (response: ServiceResponse) => {
                
                setClients(response.data.clients);
                setProviders(response.data.providers);

                const _society = response.data.society;
                _society.clients = response.data.society.clients.map(
                    (client: any) => {
                        return {
                            value: client.id,
                            label: client.name,
                        };
                    }
                );
                _society.providers = response.data.society.providers.map(
                    (provider: any) => {
                        return {
                            value: provider.id,
                            label: provider.name,
                        };
                    }
                );

                setSociety(_society);
                
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/societies');
               
                }
            }
        });

    };


    const reloadModule = () => {
        edit(parseInt(id));
    };

    const update = () => {
        if (society != null) {
            if (showLoading) showLoading('loading', 'Actualizando sociedad...');
            updateSociety(Number(society?.id), society, {
                onSuccess: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setErrorFields(undefined);
                    useReactConfirmAlert().successAlert({
                        title: 'Éxito',
                        message: response.message
                    });
                    toast.success(response.message, {
                        autoClose: 2500
                    });
                    reloadModule();
              
                },
                onError: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    useReactConfirmAlert().errorAlert({
                        title: 'Error',
                        message: response.message
                    });
                    toast.error(response.message);
                },
                onFieldError: (errorFields: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setErrorFields(errorFields.data);
                }
            });
        }
    };


    return (
        <>
            <Breadcrumbs pageSection={`Editar Sociedad #${id}`} breadcrumbs={breadcrumbs} />
            <div className="row">
                <div className="col-md-6">
                    <DefaultCard>
                        <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                        {!fetchingEditSociety ? (
                            <>
                                <SocietyForm
                                    societyForm={society} 
                                    setSocietyForm={setSociety} 
                                    clients={clients}
                                    providers={providers}
                                    errorFields={errorFields} 
                                />
                                <div className="row justify-content-end">
                                    <div className="col-auto">
                                        <ButtonSaveForm
                                            callbackSave={update}
                                            locked={fetchingUpdateSociety}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : (
                            // )
                            <LazyLoading />
                        )}
                    </DefaultCard>
                </div>
                <div className="col-md-6">
                    
                </div>
            </div>
            {/*DESCOMENTAR_PRODUCCION*/}
            {/*<Tabs defaultActiveKey="bankAccount" id="society-edit" className="mb-3">*/}
            {/*    <Tab eventKey="bankAccount" title="Cuentas Bancarias">*/}
            {/*        <BankAccountSocietyContainer*/}
            {/*            societyId={parseInt(id)}*/}
            {/*        />*/}
            {/*    </Tab>*/}
            {/*    <Tab eventKey="society-contacts" title="Contactos">*/}
            {/*        <SocietyContactContainer*/}
            {/*            societyId={parseInt(id)}*/}
            {/*        />*/}
            {/*    </Tab>*/}
            {/*    <Tab eventKey="clients" title="Clientes">*/}
            {/*        <SocietyClientContainer*/}
            {/*            societyId={parseInt(id)}*/}
            {/*        />*/}
            {/*    </Tab>*/}
            {/*    <Tab eventKey="providers" title="Proveedores">*/}
            {/*        <SocietyProviderContainer*/}
            {/*            societyId={parseInt(id)}*/}
            {/*        />*/}
            {/*    </Tab>*/}
            {/*</Tabs>*/}
        </>
    );
};

export default SocietyEdit;
